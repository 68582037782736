<template>
  <div class="blog-page blog-page-posts-show">
    <div v-if="loading">
      <div class="blog-page--hero">
        <h1 class="blog-page--page-title bg-cl-secondary brdr-radius-20 h-44 mb5" />
        <h1 class="blog-page--page-title bg-cl-secondary brdr-radius-20 h-44 mw-550" />
        <h3 class="blog-page--page-title blog-page--preview-date bg-cl-secondary brdr-radius-20 h-16 mw-200" />
        <div class="blog-page--preview-large bg-cl-secondary brdr-radius-20" />
        <div class="container bg-cl-secondary brdr-radius-20 mt20 h-16" />
        <div class="container bg-cl-secondary brdr-radius-20 mt20 h-16" />
      </div>
    </div>
    <div v-else-if="post" class="container">
      <div class="pt10">
        <breadcrumbs
          :routes="[{name: $t('Home'), route_link: '/'},{name: 'Prasa', route_link: '/pl/prasa'}]"
          :active-route="post.meta_title"
          class="center-xs blog-bradcrumbs"
        />
      </div>
      <!-- Article rich snippet -->
      <div class="hidden" itemscope itemtype="https://schema.org/Article">
        <span itemprop="datePublished">{{ post.publish_time }}</span>
        <span itemprop="headline" v-html="post.meta_title" />
        <span itemprop="image" :content="post.featured_image" />
        <span itemprop="publisher" itemtype="http://schema.org/Organization" itemscope>
          <span itemprop="name" content="E-garderobe.pl" />
          <span itemprop="logo" itemscope itemtype="https://schema.org/ImageObject">
            <img :src="hostName + '/assets/logo.png'" alt="E-Garderobe, wypożyczalnia sukienek na zbliżającą się okazję - E-Garderobe" itemprop="contentUrl">
            <span itemprop="url" :content="hostName" />
          </span>
        </span>
      </div>
      <!-- rich snippet end -->
      <h2 class="blog-page--page-title" v-html="post.title" />
      <div class="row mx0 mb20 blog-page--page-content">
        <div class="col-xs-12 col-md-12 brdr-1 brdr-cl-bg-secondary p20">
          <div class="blog-page--preview-large blog-page--preview-large-hero-merge" :style="getPostImage(post)" />
          <h3 class="blog-page--page-date blog-page--preview-date">
            {{ $t('Posted') }}: {{ post.publish_time | prettyDate }}
          </h3>
          <div class="blog-page--post-container row--entries px10" v-show="post.filtered_content && post.filtered_content.length">
            <div class="content-container-post-wordpress container" v-html="post.filtered_content" />
            <div class="env-padding-bottom py20">
              <router-link :to="localizedRoute(`/prasa`)" class="link pl10 mb10">
                {{ $t('Back to posts') }}
              </router-link>
            </div>
          </div>
          <div v-show="post.related_posts && post.related_posts.length">
            <h5 class="sidebar-categories-title mt20">
              {{ $t('Powiązane posty') }}
            </h5>
            <ol class="pl15">
              <li class="capitalize my10 related-posts" v-for="category in post.related_posts" :key="category.category_id">
                <router-link :to="localizedRoute(`/prasa/post/${category.identifier}`)" class="related-posts">
                  {{ category.title }}
                </router-link>
              </li>
            </ol>
          </div>
          <div>
            <h5 class="sidebar-categories-title" v-show="relatedProducts.length">
              {{ $t('Produkty Powiązane') }}
            </h5>
            <div>
              <products-carousel :is-blog-page="true" class="mt10" :products="relatedProducts" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>
        {{ $t('No post found.') }}
      </h2>
    </div>
  </div>
</template>

<script>
import Show from 'src/modules/vsf-wordpress/components/press/Show.js'
import config from 'config'
import { htmlDecode } from '@vue-storefront/core/filters'
import ProductsCarousel from 'theme/components/core/ProductsCarousel.vue'

export default {
  components: {
    ProductsCarousel
  },
  mixins: [Show],
  name: 'PostsShow',
  computed: {
    hostName () {
      return config.server.hostName
    }
  },
  metaInfo () {
    let title
    const meta = []
    if (this.post && this.post.meta_title) {
      title = this.post.meta_title
      meta.push({
        vmid: 'og:title',
        property: 'og:title',
        content: this.post.meta_title
      })
      meta.push({
        vmid: 'og:url',
        property: 'og:url',
        content: this.post ? htmlDecode(config.server.hostName + this.$route.path) : 'https://e-garderobe.pl'
      })
      meta.push({
        vmid: 'og:description',
        property: 'og:description',
        content: htmlDecode(this.post.meta_description)
      })
      meta.push({
        vmid: 'description',
        name: 'description',
        content: htmlDecode(this.post.meta_description)
      })
      meta.push(
        {
          property: 'og:image',
          content: this.post && this.post.image ? `${this.post.image}` : config.server.hostName + '/assets/facebook.png'
        }
      )
    }
    return {
      title,
      meta
    }
  }
}
</script>

<style lang="scss" scoped>
/* WordPress Generated Classes */
.link {
  text-decoration: underline;
}
.link:hover {
}
.subcategory {
  cursor: pointer;
  border-bottom: 1px dotted;
  padding-bottom: 10px;
}
.related-posts {
  cursor: pointer;
  padding-bottom: 10px;
}
.subcategory:hover {
  text-decoration: underline;
}
.related-posts:hover {
  text-decoration: underline;
}
.subcategory:last-child {
  border-bottom: none;
}
.blog-page-posts-show {
  .sidebar-categories-title {
    color: #121212;
    background-color: transparent;
    text-transform: uppercase;
    position: relative;
    padding: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
    min-width: 100%;
    border-bottom: 1px solid #121212;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .alignnone {
      margin: 5px 20px 20px 0;
  }

  .aligncenter,
  div.aligncenter {
      display: block;
      margin: 5px auto 5px auto;
  }

  .alignright {
      float:right;
      margin: 5px 0 20px 20px;
  }

  .alignleft {
      float: left;
      margin: 5px 20px 20px 0;
  }

  a img.alignright {
      float: right;
      margin: 5px 0 20px 20px;
  }

  a img.alignnone {
      margin: 5px 20px 20px 0;
  }

  a img.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
  }

  a img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
  }

  .wp-caption {
      background: #fff;
      border: 1px solid #f0f0f0;
      max-width: 96%; /* Image does not overflow the content area */
      padding: 5px 3px 10px;
      text-align: center;
  }

  .wp-caption.alignnone {
      margin: 5px 20px 20px 0;
  }

  .wp-caption.alignleft {
      margin: 5px 20px 20px 0;
  }

  .wp-caption.alignright {
      margin: 5px 0 20px 20px;
  }

  .wp-caption img {
      border: 0 none;
      height: auto;
      margin: 0;
      max-width: 98.5%;
      padding: 0;
      width: auto;
  }

  .wp-caption p.wp-caption-text {
      font-size: 11px;
      line-height: 17px;
      margin: 0;
      padding: 0 4px 5px;
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  }

  .screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
  }
  // bootstrap form styles
  .form-group {
    padding: 0 10px;
    label {
      display: inline-block;
      margin-bottom: .5rem;
    }
  }
  .form-control {
    height: inherit;
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none;
  }
  .btn.btn-primary {
    cursor: pointer;
    border: 1px solid #0d6d37;
    background: #108442;
    padding: .5rem .75rem;
    line-height: 1.25;
    box-sizing: border-box;
    font-size: 1rem;
    opacity: .65;
    color: #fff;
    text-align: center;
    margin: 0;
    border-radius: 4px;
  }
  .wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 1em;
    input {
      height: inherit;
    }
  }
}
.embed-youtube {
  margin-bottom: 24px;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive-16by9:before {
    padding-top: 56.25%;
}
.embed-responsive:before {
    display: block;
    content: "";
}
.embed-responsive iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
/* WordPress Generated Classes END */
.blog-page-posts-show img {
  max-width: 100%;
  height: auto;
}
.content-container-post-wordpress p {
  line-height: 1.5;
}
.content-container-post-wordpress a {
  color: #307BBF;
}
.content-container-post-wordpress a:active {
  color: #954F72;
}
.content-container-post-wordpress a:visited {
  color: #954F72;
}
</style>
<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.blog-page--preview-large-hero-merge {
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    margin-top: 0 !important;
  }
}

.blog-page-posts-show {
  .blog-page--hero {
    padding-bottom: 10px;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }

  .blog-page--page-title {
    text-align: center;
    display: block;
    color: $nearly-black;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .blog-page--page-date.blog-page--page-date {
    display: block;
    color: $nearly-black;
    font-size: 16px;
    margin: 20px auto 10px;
    font-weight: 400;
  }

  .blog-page--preview-large {
    &:before {
      display: none;
    }
  }
  .blog-page--post-container {
    h3 {
      color: $nearly-black;
      margin: 20px 0;
    }
    p {
      font-family: $PTSans;
      margin: 0 0 40px;
      font-size: 19px;
      line-height: 1.65;
      color: #444;

    }
    p + h3 {
      margin-top: 20px * 2;
    }
    img {
      box-shadow: 0 2px 10px 1px rgba(65, 64, 64, 0.2);
      border-radius: 5px;
      margin: 20px 0 30px;
    }
  }
  .blog-page--entries {
    padding: 60px 0 20px;
    background: #f9f9f9;
    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
  .h-44 {
    height: 44px;
  }
  .h-16 {
    height: 16px;
  }
  .mw-550 {
    max-width: 550px !important;
  }
  .mw-200 {
    max-width: 200px !important;
  }
/* Progress Bar */
.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #18357b;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 5px 0 0 0;
  overflow: hidden;
}
  .progress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: #041D59;
    transition: width .3s linear;
  }
  .progress .indeterminate {
    background-color: #041D59;
  }
    .progress .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  .progress .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s;
  }

  @-webkit-keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%; }
    60% {
      left: 100%;
      right: -90%; }
    100% {
      left: 100%;
      right: -90%; } }
  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%; }
    60% {
      left: 100%;
      right: -90%; }
    100% {
      left: 100%;
      right: -90%; } }
  @-webkit-keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%; }
    60% {
      left: 107%;
      right: -8%; }
    100% {
      left: 107%;
      right: -8%; } }
  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%; }
    60% {
      left: 107%;
      right: -8%; }
    100% {
      left: 107%;
      right: -8%;
    }
  }
  .blog-page--page-content {
    @media (max-width: 767px) {
      width: 100%;
    }
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .blog-bradcrumbs {
    justify-content: center !important;
  }
</style>
